import { FeatureFlagContext } from "@src/contexts/FeatureFlagContext";
import { useContext } from "react";
import { Ff } from "@src/types.generated";

export const useFeatureFlag = (flag: string | Ff): boolean => {
  const flags = useContext(FeatureFlagContext);
  if (window?.__PLAYWRIGHT_FEATURE_FLAG_OVERRIDES__?.[flag] !== undefined) {
    return window.__PLAYWRIGHT_FEATURE_FLAG_OVERRIDES__[flag];
  }
  return flags[flag] || false;
};
