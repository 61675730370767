import { CoverImageFragment } from "@src/fragments.generated";
import { MediaFragment } from "@src/fragments.generated";
import { getLinkType, LinkType } from "./files";

export const getMediaUrl = (
  media: Pick<MediaFragment, "imageUrls" | "mediaUrls" | "unoptimizedUrl">,
  opts?: { thumb?: boolean },
): string => {
  if (
    media.unoptimizedUrl &&
    getLinkType(media.unoptimizedUrl) === LinkType.GIF
  ) {
    return media.unoptimizedUrl;
  }
  if (opts?.thumb && media.imageUrls?.thumb) {
    return media.imageUrls?.thumb;
  }
  return (
    media.imageUrls?.medium || media.imageUrls?.original || media.mediaUrls.en
  );
};

export const getThumbnailUrl = (media: Omit<MediaFragment, "id">): string => {
  return (
    media.unoptimizedUrl ||
    media.imageUrls?.thumb ||
    media.imageUrls?.medium ||
    media.imageUrls?.original ||
    media.mediaUrls.en
  );
};

export const getCoverImageUrl = (
  coverImage: Pick<CoverImageFragment, "imageUrls">,
): string | undefined => {
  return (
    coverImage.imageUrls?.wide ||
    coverImage.imageUrls?.medium ||
    coverImage.imageUrls?.original
  );
};

export const isVimeoRedirectLink = (link: string): boolean => {
  return link.toLowerCase().includes("player.vimeo.com/progressive_redirect/");
};
