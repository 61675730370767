import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { TranslationSetFragmentDoc, CoverImageFragmentDoc, ContentTagMembershipFragmentDoc, ImageUrlsFragmentDoc, MediaFragmentDoc, TrainingResourceVersionFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, ConvertMediaToRichTextDocumentWorkflowFragmentDoc, BlastSentRecordFragmentDoc, TrainingResourceFragmentDoc } from '../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddMediaResources_TrainingResourceFragment = { __typename?: 'TrainingResource', id: string, libraryItem: { __typename?: 'LibraryItem', createdAt: string, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, description: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } }, publishedVersion?: { __typename?: 'TrainingResourceVersion', id: string, media?: { __typename?: 'Media', id: number, loopVideo: boolean, hideVideoControls: boolean, unoptimizedUrl?: string | null, createdAt: string, updatedAt: string, thumbnailImageUrl?: string | null, mediaUrls: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, trainingResourceVersions?: Array<{ __typename?: 'TrainingResourceVersion', id: string, trainingResource: { __typename?: 'TrainingResource', id: string } }> | null } | null } | null };

export type AddMediaResourcesTrainingResourcesQueryVariables = Types.Exact<{
  search: Types.Scalars['String'];
}>;


export type AddMediaResourcesTrainingResourcesQuery = { __typename?: 'Query', TrainingResources: Array<{ __typename?: 'TrainingResource', id: string, libraryItem: { __typename?: 'LibraryItem', createdAt: string, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, description: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } }, publishedVersion?: { __typename?: 'TrainingResourceVersion', id: string, media?: { __typename?: 'Media', id: number, loopVideo: boolean, hideVideoControls: boolean, unoptimizedUrl?: string | null, createdAt: string, updatedAt: string, thumbnailImageUrl?: string | null, mediaUrls: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, trainingResourceVersions?: Array<{ __typename?: 'TrainingResourceVersion', id: string, trainingResource: { __typename?: 'TrainingResource', id: string } }> | null } | null } | null }> };

export const AddMediaResources_TrainingResourceFragmentDoc = gql`
    fragment AddMediaResources_TrainingResource on TrainingResource {
  id
  libraryItem {
    createdAt
    name {
      ...TranslationSet
    }
    description {
      ...TranslationSet
    }
  }
  publishedVersion {
    id
    media {
      ...Media
    }
  }
}
    ${TranslationSetFragmentDoc}
${MediaFragmentDoc}`;
export const AddMediaResourcesTrainingResourcesDocument = gql`
    query AddMediaResourcesTrainingResources($search: String!) {
  TrainingResources: AdminTrainingResources(search: $search) {
    ...AddMediaResources_TrainingResource
  }
}
    ${AddMediaResources_TrainingResourceFragmentDoc}`;

/**
 * __useAddMediaResourcesTrainingResourcesQuery__
 *
 * To run a query within a React component, call `useAddMediaResourcesTrainingResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMediaResourcesTrainingResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMediaResourcesTrainingResourcesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAddMediaResourcesTrainingResourcesQuery(baseOptions: Apollo.QueryHookOptions<AddMediaResourcesTrainingResourcesQuery, AddMediaResourcesTrainingResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMediaResourcesTrainingResourcesQuery, AddMediaResourcesTrainingResourcesQueryVariables>(AddMediaResourcesTrainingResourcesDocument, options);
      }
export function useAddMediaResourcesTrainingResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMediaResourcesTrainingResourcesQuery, AddMediaResourcesTrainingResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMediaResourcesTrainingResourcesQuery, AddMediaResourcesTrainingResourcesQueryVariables>(AddMediaResourcesTrainingResourcesDocument, options);
        }
export type AddMediaResourcesTrainingResourcesQueryHookResult = ReturnType<typeof useAddMediaResourcesTrainingResourcesQuery>;
export type AddMediaResourcesTrainingResourcesLazyQueryHookResult = ReturnType<typeof useAddMediaResourcesTrainingResourcesLazyQuery>;
export type AddMediaResourcesTrainingResourcesQueryResult = Apollo.QueryResult<AddMediaResourcesTrainingResourcesQuery, AddMediaResourcesTrainingResourcesQueryVariables>;